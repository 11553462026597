import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import {
  NavDropdown
} from 'react-bootstrap'

import LocalImage from './local-image'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareAlt } from '@fortawesome/free-solid-svg-icons'

import './share.css'



class Share extends Component {

    onClick = (e) => {
        e.preventDefault()
        alert('Copied')
        // console.log(`Clicked on "${innerHTML}"!`); // eslint-disable-line
    }

    render() {
        const display = this.props.display || false
        const url = this.props.url || '/'
        const title = this.props.title || ''
        const pic = this.props.pic || ''
        const appkey = this.props.appkey || ''
        const caption = this.props.caption || ''

        return (
            <StaticQuery
                query={graphql`{
                    site {
                        siteMetadata {
                            title
                            domain
                        }
                    }
                }`}
                render={(data) => {
                    const domain = data.site.siteMetadata.domain
                    const shareUrl = `https://${domain}${url}`
                    const urlWeibo = `http://service.weibo.com/share/share.php?url=${shareUrl}&title=${title}&pic=${pic}&appkey=${appkey}`
                    const urlTwitter = `https://twitter.com/intent/tweet?url=${shareUrl}&hashtags=ARW&via=acidrainworld`
                    const urlFb = `https://www.facebook.com/share.php?u=${shareUrl}`
                    const urlWA = `whatsapp://send?text=${title} - ${shareUrl}`

                    // DEBUG:
                    // console.log(`==== Share : this.props ==== `, this.props)
                    // console.log(`==== Share : data ==== `, data)

                    let html = ''
                    if (display === 'row'){
                        html =
                        <div className="share-box">
                            <span>{caption} </span>
                            <div className="shareMenu rowMenu d-flex justify-content-center">
                                {/* Facebook */}
                                <a href={urlFb} target="_blank" rel="noopener " >
                                    {/* <FontAwesomeIcon size="lg" icon={faFacebook} /> */}
                                    <LocalImage name="sns_fb" attr={{ className: "img-fluid", alt: "Facebook" }} />
                                </a>
                                
                                {/* Twitter */}
                                <a href={urlTwitter} target="_blank" rel="noopener " >
                                    {/* <FontAwesomeIcon size="lg" icon={faTwitter} /> */}
                                    <LocalImage name="sns_twitter" attr={{ className: "img-fluid", alt: "Twitter" }} />
                                </a>
                                
                                {/* Copy Link */}
                                <CopyToClipboard text={shareUrl}>
                                    <a href="#sns_copylink" onClick={this.onClick} data-clipboard-text={url} target="_blank" rel="noopener ">
                                        {/* <FontAwesomeIcon icon={faLink} size="lg" /> */}
                                        <LocalImage name="sns_copylink" attr={{ className: "img-fluid", alt: "Copy Link" }} id="sns_copylink" />
                                    </a>
                                </CopyToClipboard>
                                
                                {/* Weibo */}
                                <a href={urlWeibo} target="_blank" rel="noopener " className="d-none d-md-block">
                                    {/* <FontAwesomeIcon size="lg" icon={faWeibo} /> */}
                                    <LocalImage name="sns_weibo" attr={{ className: "img-fluid", alt: "Twitter" }} />
                                </a>
                                
                                {/* Whatsapp */}
                                <a href={urlWA} target="_blank" rel="noopener " className="d-block d-md-none">
                                    {/* <FontAwesomeIcon size="lg" icon={faWeibo} /> */}
                                    <LocalImage name="sns_whatsapp" attr={{ className: "img-fluid", alt: "Whatsapp" }} />
                                </a>

                            </div>
                        </div>

                    }else{
                        html =
                        <div className="shareMenu rciMenu">
                            <div className="iconMenu">
                                <div className="d-flex flex-row">
                                    <NavDropdown title={<FontAwesomeIcon icon={faShareAlt} className="img-fluid" />}
                                        id="share-omanga" className="icon-box caret-off  d-flex" alignRight>


                                        {/* Facebook */}
                                        <NavDropdown.Item href={urlFb} target="_blank" rel="noopener " >
                                            {/* <FontAwesomeIcon icon={faFacebook} size="lg" /> */}
                                            <LocalImage name="sns_fb" attr={{ className: "img-fluid", alt: "Facebook" }} />
                                        </NavDropdown.Item>

                                        {/* Twitter */}
                                        <NavDropdown.Item href={urlTwitter} target="_blank" rel="noopener " >
                                            {/* <FontAwesomeIcon icon={faTwitter} size="lg" /> */}
                                            <LocalImage name="sns_twitter" attr={{ className: "img-fluid", alt: "Twitter" }} />
                                        </NavDropdown.Item>

                                        {/* Copy Link */}
                                        <CopyToClipboard text={shareUrl}>
                                            <NavDropdown.Item href="#" onClick={this.onClick} data-clipboard-text={url} target="_blank" rel="noopener " >
                                                {/* <FontAwesomeIcon icon={faLink} size="lg" /> */}
                                                <LocalImage name="sns_copylink" attr={{ className: "img-fluid", alt: "Copy Link" }} />
                                            </NavDropdown.Item>
                                        </CopyToClipboard>

                                        {/* Weibo */}
                                        <NavDropdown.Item href={urlWeibo} target="_blank" rel="noopener " className="d-none d-md-block">
                                            {/* <FontAwesomeIcon icon={faWeibo} size="lg" /> */}
                                            <LocalImage name="sns_weibo" attr={{ className: "img-fluid", alt: "Weibo" }} />
                                        </NavDropdown.Item>
                                        
                                        {/* Whatsapp */}
                                        <NavDropdown.Item href={urlWA} target="_blank" rel="noopener " className="d-block d-md-none">
                                            {/* <FontAwesomeIcon icon={faWeibo} size="lg" /> */}
                                            <LocalImage name="sns_whatsapp" attr={{ className: "img-fluid", alt: "Whatsapp" }} />
                                        </NavDropdown.Item>

                                    </NavDropdown>
                                </div>
                            </div>
                        </div>
                    }



                    return ( html )
            }}
            />
    )
    }

}



export default Share
