import React, { Component } from 'react'
import _ from 'lodash'
// import {
//   Row, Col, Button, Card
// } from 'react-bootstrap'
import Moment from 'react-moment'
import { withNamespaces } from 'react-i18next'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faClock, faNewspaper, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

// import PrismicHTML from '../prismic-html'
// import Share from '../share'

class NewsList extends Component {
    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        // this._handleCardToggle = this._handleCardToggle.bind(this)

        this.state = {
            url: this._getUrl()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.news.id !== this.props.news.id) {
            this.setState({
                url: this._getUrl()
            })
        }
    }

    _getUrl() {
        const item = this.props.news
        const enNews = this.props.enNews

        let _url = item.slug || ''
        // console.log(`_getUrl _url = item.slug (${item.slug}) = ${_url}`)

        if ((_url === '') && (enNews)){
            if (item.lang === 'en-gb') {
                _url = `${item.head.title.text.match(/[A-Za-z0-9]+/g).join('-').toLowerCase()}`
            } else {
                let enId = _.filter(item.alternate_languages, {'lang': 'en-gb'})[0].id
                let enPd = _.find(enNews, {id: enId})
                _url = `${enPd.data.title.text.match(/[A-Za-z0-9]+/g).join('-').toLowerCase()}`
            }
        }
        // console.log(`return _url = ${_url}`)
        return _url
    }

    render() {
        // const t = this.props.t
        // console.log(this.props.news)
        // console.log(` ==== this.props : news compontent page ==== `, this.props )

        // const rawJson = this.props.news.rawJson
        const newsId = this.props.news.id
        const newsType = (this.props.ftypelist) ? this.props.ftypelist[this.props.news.head.type][0] : this.props.news.head.type
        const newsTitle = this.props.news.head.title.text
        const newsDate = this.props.news.head.datetime
        const newsThumb = this.props.news.head.thumbnail.url 
        const newsSlug = this.state.url
        const _path = (newsType === "codex") ? 'codex' : 'news'
        // console.log(`newsSlug = ${newsSlug} newsId= ${newsId} , newsType= ${newsType}, newsTitle= ${newsTitle}, newsDate= ${newsDate}, rawJson = (newsThumb = ${newsThumb}) } `, rawJson.content)


        // const lang = this.props.pageContext.lng
        // const newslist = this.props.newslist
        // console.log(`news list = `, newslist)

        return (
            <>
                {
                    <a href={`/${this.props.lng}/${_path}/${newsSlug}`} key={`news-l-${newsId}`} className={`col-12 col-md-4 col-lg-3 ${this.props.hs}`} rel="noopener ">
                        <div className="plist-img text-center">
                            {
                                ( newsThumb === undefined || newsThumb === null ) ? 
                                    null 
                                    :
                                    // <LocalImage name={item.typeobj.IconImage.split('.')[0]} attr={{className: "img-fluid", alt:"Factions"}} />
                                    <img src={newsThumb} alt={newsTitle} className="img-fluid" />
                            }
                        </div>
                        <div className="plist-box">
                            {
                                (newsType === "codex") ?
                                    null
                                    :
                                    <>
                                    <div className="plist-type">{newsType}</div>
                                    <div className="plist-date"><Moment format="DD/MM/YYYY">{newsDate}</Moment></div>
                                    </>
                            }
                            <div className="plist-title">
                                {newsTitle}
                            </div>
                        </div>
                    </a>
                }
            </>
        )
    }
}

export default withNamespaces()(NewsList)
